import { v4 as uniqueIDentifier  } from 'uuid'
import store from '../store'

const formatLocations = ( locations ) => {    
    locations.forEach( location => {                                   
        delete location.backup,        
        delete location.montaje 
        location.uuid = uniqueIDentifier(),                
        location.name = capitalizeFirstLetterString( location.name ) 
        location.hotel = findHotel( location.Hotel )         
        location.idHotel = location.Hotel        
        delete location.Hotel        
        delete location.deleted        
        delete location.createdate
        delete location.status
        delete location.bloqueajunto
    })                   
    locations.unshift( { name: 'Todos', id: 0 } )
    return { locations }
}



const createArrayMontages = (locations, montages ) => {            
    locations.forEach( (location ) =>{
        if( location.montaje.length ){
            const idLocation = location.id
        }
    })
}

const capitalizeFirstLetterString = ( string ) => {
    const cadena = string.toLowerCase()
    const arr = cadena.split(" ") 
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    
    }
    return arr.join(" ")
}


const findHotel = ( id ) => {
    const hotel =  store.state.hotels.find( hotel => hotel.id == id )  
    return hotel ? `${ hotel.name }` : ''    
}



export { formatLocations }