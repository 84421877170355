<template>   
    <!-- hide-header-close  -->
    <b-modal id="modal-create-event" title="Crear evento" size="lg"
      no-close-on-backdrop hide-footer hide-header-close       
    >
        <b-card-text>            
            <ValidationObserver ref="formEvent" tag="form">
                <b-form @submit.prevent="handlerSaveEvent">
                    <b-row>
                        <b-col md="6">
                            <ValidationProvider name="locacion" rules="required">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Locación *</strong>
                                    <b-form-select
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="event.locacion" 
                                    >
                                    <option :value="null">Seleccione locación</option>
                                    <option
                                        v-for="(location, index) in locationsWithOutAll"
                                        :key="index"
                                        :value="location.id"
                                    >{{location.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider rules="required" name="nombre de evento">
                                <b-form-group slot-scope="{ valid, errors }">
                                <strong>Nombre evento *</strong>
                                <b-input
                                    placeholder="Ingrese nombre del evento"
                                    v-model="event.name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider rules="required" name="fecha inicio">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Fecha inicio * </strong>                   
                                    <b-input                                        
                                        type="date"                                                        
                                        v-model="event.dateInit"            
                                        :min="currentDateInput"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="text-center">
                                <b-form-group>
                                    <strong>Hora inicio * </strong>
                                    <antTimePicker
                                        :minuteStep="5"
                                        format="HH:mm"
                                        :value="moment(event.startHour,'HH:mm')"
                                        :defaultValue="moment('10:00','HH:mm')"
                                        @change="onHourChangeInit"
                                    ></antTimePicker>
                                </b-form-group>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider rules="required" name="fecha fin">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Fecha fin *</strong>
                                    <b-input                                        
                                        type="date"                        
                                        v-model="event.dateEnd"
                                        :min="currentDateInput"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="text-center">
                                <b-form-group>
                                    <strong>Hora fin* </strong>     
                                    <antTimePicker
                                        :minuteStep="5"
                                        :value="moment(event.endHour,'HH:mm')"
                                        :defaultValue="moment('11:00','HH:mm')"
                                        format="HH:mm"
                                        @change="onHourChangeEnd"
                                    ></antTimePicker>
                                </b-form-group>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="montaje" rules="">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Montaje</strong>
                                    <b-form-select
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="event.montage" 
                                    >
                                    <option :value="null">Seleccione montaje</option>
                                    <option
                                        v-for="(montage, index) in montages"
                                        :key="index"
                                        :value="montage.id"
                                    >{{montage.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="backup" rules="">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Backup</strong>
                                    <b-form-select
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="event.backup" 
                                    >
                                    <option :value="null">Seleccione backup</option>
                                    <option
                                        v-for="(bLocation, index) in backupLocations"
                                        :key="index"
                                        :value="bLocation.id"
                                    >{{bLocation.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="group" rules="required">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Grupo</strong>
                                    <b-form-select
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="event.group" 
                                    >
                                    <option :value="null">Seleccione grupo</option>
                                    <option
                                        v-for="(group, index ) in groups"
                                        :key="index"
                                        :value="group.id"
                                    >{{group.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider rules="required" name="adultos">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Adultos*</strong>                            
                                        <b-input                                            
                                            v-model="event.adults"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                        <b-col md="6">
                            <ValidationProvider rules="required" name="niños">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Niños*</strong>                            
                                        <b-input                                            
                                            v-model="event.children"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                        <b-col md="6">
                            <ValidationProvider rules="required" name="infantes">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Infantes*</strong>                            
                                        <b-input                                            
                                            v-model="event.infants"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                    </b-row>                    
                    <b-row>
                        <b-col md="6">
                            <b-button variant="danger" @click="closeModal">Cerrar</b-button>
                        </b-col>
                        <b-col md="6">                        
                            <b-button type="submit" variant="success" class="float-right" >Crear evento</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>             
        </b-card-text>
    </b-modal>     
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import * as moment from 'moment'
import { currentDate } from '@/modules/calendar/helpers/currentDate'
import { toJson } from '@/modules/calendar/helpers/toJson'
import { showAlertMessage } from '@/modules/calendar/helpers/showNotification'

export default {
    directives: {
        Ripple,
    },
    data(){
        return {
            event:{                             
                adults: 1,
                backup: null,            
                children: 0,
                dateEnd:'',
                dateInit: '',
                endHour: moment().hour(11).minute(0),   
                group: null,
                infants: 0, 
                locacion: null,
                montage: null, 
                name: "",
                startHour: moment().hour(10).minute(0),               
            }
        }
    },       
    computed:{
        ...mapState('auth',['idUser']),        
        ...mapState('calendar', ['selectedDate','groups','backupLocations','montages']), 
        ...mapGetters('calendar',['locationsWithOutAll']),
        currentDateInput(){
            return currentDate()
        },  
        computedForm(){
            return this.$refs.formEvent
        }                 
    },
    methods:{
        moment,
        ...mapActions('calendar',['validateEvent','saveEvent']),
        onlyNumber(event){      
            let keyCode = (event.keyCode ? event.keyCode : event.which)
            const codesAllowed = [ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if ( !respuesta ) {                                       
				event.preventDefault()
			}
        }, 
        closeModal(){
            this.$root.$emit('bv::hide::modal', 'modal-create-event')
        }, 
        onHourChangeInit( hour ){            
            if( hour && hour !== ''){
                this.event.startHour = hour                                                         
            }
        },
        onHourChangeEnd( hour ){
            if( hour && hour !== ''){
                this.event.endHour = hour               
            }
        },
        async handlerSaveEvent(){
            const event = toJson( { ...this.event } ) //break referece with spread operator
            const isValidForm = await this.validateFields() //validate required fields          
            const isValidDates = this.validateDates( event ) //validate dates 
            
            if ( !isValidForm ) return   
            
            if( !isValidDates ){
                const text = `La fecha de inicio no debe ser menor a la fecha final 😪😪`
                showAlertMessage( 'Fechas invalidas', 'InfoIcon', text, 'danger', 4000, 'top-right')
                return
            }
            const payloadEvent = this.createPayloadEvent( event )
            const { ok: okValidate, message } = await this.validateEvent( payloadEvent )

            if( !okValidate ){
                showAlertMessage( 'Error', 'InfoIcon', message, 'danger', 4000, 'top-right')
            }
            //if okValidate, save event
            if( okValidate ){
                const { ok: savedEvent, message } = await this.saveEvent( payloadEvent )
                if( savedEvent ){
                    Object.assign( this.$data, this.$options.data() ) 
                    this.$refs.formEvent.reset()  
                    this.closeModal()
                    showAlertMessage( 'Ok', 'InfoIcon', message, 'success', 4000, 'top-right')
                }
                if( !savedEvent ){
                    showAlertMessage( 'Error', 'InfoIcon', message, 'danger', 4000, 'top-right')
                }          
            }
        },
        validateFields(){
            return this.computedForm.validate()
        },
        validateDates( event ){
            const { dateEnd, dateInit } = event // desestructuro grupo
            if( dateEnd != "" && dateInit != "" ){
                return moment( dateEnd ).isSameOrAfter( dateInit, 'day')             
            } 
            return true            
        },
        createPayloadEvent( event ){
            
            const horaInicio = this.event.startHour.format("HH:mm")
            const horaFin = this.event.endHour.format("HH:mm")
            const payload = {                
                createdby: this.idUser,
                idLoc: event.locacion, //validar en /grupos/validacion/ 
                fini: event.dateInit, //validar en /grupos/validacion/ 
                hini: horaInicio, //validar en /grupos/validacion/ 
                ffin: event.dateEnd, //validar en /grupos/validacion/ 
                hfin: horaFin, //validar/grupos/validacion/              
                locacion: event.locacion,
                nombreEvento: event.name,
                montaje: event.montage,
                backup: event.backup,
                grupo: event.group, // 22438,
                startDate: event.dateInit,
                endDate: event.dateEnd,
                startTime: horaInicio,
                endTime: horaFin, 
                adult: parseInt(event.adults),
                child: parseInt(event.children),
                infant:  parseInt(event.infants),                                      
            }
            if( !payload.backup ){
                delete payload.backup                
            }
            if( !payload.montaje ){
                delete payload.montaje                
            }
                       
            return payload
        }
    }
}
</script>