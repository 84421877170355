<template>   
    <!-- hide-header-close  -->
    <b-modal id="modal-edit-group" :title="'Actualizar: '+selectedGroup.name" size="lg"
      no-close-on-backdrop hide-footer        
    >
        <b-card-text>            
            <ValidationObserver ref="editGroupForm" tag="form">
                <b-form @submit.prevent="handlerUpdateGroup">
                    <b-row>
                        <b-col md="4">
                            <ValidationProvider rules="required" name="nombre grupo">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Nombre grupo*</strong>
                                    <b-input
                                        placeholder="Nombre del grupo"
                                        v-model="selectedGroup.name"
                                        :state="errors[0] ? false : (valid ? true : null)" >
                                    </b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="4">
                            <ValidationProvider rules="required" name="clasificacion">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Clasificación*</strong>                            
                                        <b-input
                                            placeholder="Clasificación del grupo"
                                            v-model="selectedGroup.clasificacion"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                        <b-col md="4">
                            <!-- @change="handleHotelClient(cart.cliente.hotel)" -->
                            <ValidationProvider name="tipo de grupo" rules="required">
                                <b-form-group slot-scope="{ valid, errors }" >
                                    <strong>Tipo de grupo*</strong>                            
                                    <b-form-select 
                                      :state="errors[0] ? false : (valid ? true : null)"          v-model="selectedGroup.tipogrupo"
                                    >
                                        <option :value="null">Seleccione tipo</option>
                                        <option
                                            v-for="groupType in groupTypesWithOutAll"
                                            :key="groupType.id"
                                            :value="groupType.id"
                                        >{{groupType.tipogruponame}}</option>
                                    </b-form-select>
                                      <b-form-invalid-feedback>
                                      {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                  </b-form-group>
                              </ValidationProvider>
                        </b-col>
                        <b-col md="4">
                            <ValidationProvider rules="" name="wedding id">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Wedding Id</strong>
                                    <b-input
                                        placeholder="Ingrese Wedding Id"
                                        v-model="selectedGroup.weddingId"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-input>                            
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>                        
                            </ValidationProvider>                        
                        </b-col>  
                        <b-col md="4">
                            <ValidationProvider rules="" name="Fecha llegada">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Fecha llegada</strong>                   
                                    <b-input
                                        id="dateArrival"
                                        type="date"                                                        
                                        v-model="selectedGroup.arrivaldate"            
                                        :min="currentDateInput"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>                        
                        </b-col>
                        <b-col md="4">
                            <ValidationProvider rules="" name="Fecha Salida">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Fecha salida</strong>
                                    <b-input
                                        id="dateDeparture"
                                        type="date"                        
                                        v-model="selectedGroup.departuredate"
                                        :min="currentDateInput"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>                        
                        </b-col>
                        <b-col md="4">
                            <ValidationProvider rules="required" name="adultos">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Adultos*</strong>                            
                                        <b-input                                            
                                            v-model="selectedGroup.adult"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                        <b-col md="4">
                            <ValidationProvider rules="required" name="niños">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Niños*</strong>                            
                                        <b-input                                            
                                            v-model="selectedGroup.child"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                        <b-col md="4">
                            <ValidationProvider rules="required" name="infantes">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Infantes*</strong>                            
                                        <b-input                                            
                                            v-model="selectedGroup.infant"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>  
                        <b-col md="4">                            
                            <ValidationProvider name="hotel" rules="required">
                                <b-form-group slot-scope="{ valid, errors }" >
                                    <strong>Hotel para el grupo*</strong>                            
                                    <b-form-select 
                                      :state="errors[0] ? false : (valid ? true : null)"          v-model="selectedGroup.idHotel"
                                    >
                                        <option :value="null">Seleccione hotel</option>
                                        <option
                                            v-for="hotel in hotelsWithOutAll"
                                            :key="hotel.id"
                                            :value="hotel.id"
                                        >{{hotel.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="4">                            
                            <ValidationProvider name="agencia" rules="">
                                <b-form-group slot-scope="{ valid, errors }" >
                                    <strong>Agencia para el grupo*</strong>                            
                                    <b-form-select 
                                      :state="errors[0] ? false : (valid ? true : null)"          v-model="selectedGroup.agency"
                                    >
                                        <option :value="null">Seleccione agencia</option>
                                        <option
                                            v-for="agency in agencies"
                                            :key="agency.id"
                                            :value="agency.id"
                                        >{{agency.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="4">                            
                            <ValidationProvider name="agente" rules="">
                                <b-form-group slot-scope="{ valid, errors }" >
                                    <strong>Agente para el grupo*</strong>                            
                                    <b-form-select 
                                      :state="errors[0] ? false : (valid ? true : null)"          v-model="selectedGroup.agentname"
                                    >
                                        <option :value="null">Seleccione agente</option>
                                        <option
                                            v-for="agent in agents"
                                            :key="agent.id"
                                            :value="agent.id"
                                        >{{agent.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>  
                        <b-col md="6">                            
                            <ValidationProvider name="coordinador" rules="">
                                <b-form-group slot-scope="{ valid, errors }" >
                                    <strong>Coordinador para el grupo*</strong>                            
                                    <b-form-select 
                                      :state="errors[0] ? false : (valid ? true : null)"          v-model="selectedGroup.idCoordinador"
                                    >
                                        <option :value="null">Seleccione coordinador</option>
                                        <option
                                            v-for="coordinator in coordinators"
                                            :key="coordinator.id"
                                            :value="coordinator.id"
                                        >{{coordinator.name}} {{coordinator.lastname}} </option>
                                    </b-form-select>
                                    <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                        <b-col md="6">
                            <ValidationProvider rules="" name="Descripción">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Descripción</strong>
                                    <b-form-textarea
                                        id="textareaDescripcion"                        
                                        placeholder="Breve descripción" 
                                        v-model="selectedGroup.description" 
                                        :state="errors[0] ? false : (valid ? true : null)"                     
                                    ></b-form-textarea>                                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>                        
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6">
                            <b-button variant="danger" @click="closeModal">Cerrar</b-button>
                        </b-col>
                        <b-col md="6">
                        <!-- :disabled="invalid" -->
                            <b-button type="submit" variant="success" class="float-right" >Actualizar grupo</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
        </b-card-text>
    </b-modal>     
</template>

<script>
import * as moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { mapState, mapActions, mapGetters } from 'vuex'
import { currentDate } from '@/modules/calendar/helpers/currentDate'
import { toJson } from '@/modules/calendar/helpers/toJson'
import { showAlertMessage } from '@/modules/calendar/helpers/showNotification'

export default {
    directives: {
        Ripple,
    },
    components:{
    },          
    computed:{  
        ...mapState('auth',['idUser']),
        ...mapState('calendar', ['selectedGroup','agencies', 'agents', 'coordinators']),
        ...mapGetters('calendar',['groupTypesWithOutAll','hotelsWithOutAll']),
        currentDateInput(){
            return currentDate()
        },
        computedForm(){
            return this.$refs.editGroupForm
        }                     
    },
    methods:{
        ...mapActions('calendar',['updateGroup']),   
        onlyNumber(event){      
            let keyCode = (event.keyCode ? event.keyCode : event.which)            
            //32 espacio, 43 es +, 45 es guion -,  48-57(numeros del 0 al 9)
            // const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const codesAllowed = [ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if ( !respuesta ) {                                       
				event.preventDefault()
			}
        },
        async handlerUpdateGroup(){
            const grupo = toJson( { ...this.selectedGroup } ) //rompo la referencia con spread            
            const isValidForm = await this.validateFields() //valido los campos
            const isValidDates = this.validateDates( grupo ) //valido fechas si traen valores            
            if ( !isValidForm ) return  

            if( !isValidDates ){
                const text = `😪😪 La fecha de llegada no debe ser menor a la fecha de salida`
                showAlertMessage( 'Fechas invalidas', 'InfoIcon', text, 'danger', 4000, 'top-right')
                return
            }
            
            const payloadGroup = this.createPayloadGroup( grupo )            
            const { ok, message } = await this.updateGroup( payloadGroup )

            if( ok ){                  
                this.closeModal()
                showAlertMessage( 'Ok', 'InfoIcon', message, 'success', 4000, 'top-right')
            }
            if( !ok ){
                showAlertMessage( 'Error', 'InfoIcon', message, 'danger', 4000, 'top-right')
            }            
        },
        validateFields(){
            return this.computedForm.validate()
        },
        validateDates( group ){
            const { arrivaldate, departuredate } = group // desestructuro grupo            
            if( arrivaldate != null && departuredate != null ){
                return moment( departuredate ).isSameOrAfter( arrivaldate, 'day')             
            } 
            return true            
        },
        createPayloadGroup( group ){
            group.coordinador = group.idCoordinador
            group.grouphotel = group.idHotel
            group.updatedby = this.idUser
            if( !group.agency ){
                delete group.agency                
            }
            if( !group.agentname ){
                delete group.agentname                
            }
            if( !group.coordinador ){
                delete group.coordinador                
            }
            if( !group.tipogrupo ){
                delete group.tipogrupo                
            }
            if ( group.arrivaldate == null || group.departuredate == null ){
                group.arrivaldate = null 
                group.departuredate = null        
            }            
            return group
        },
        closeModal(){
            this.$root.$emit('bv::hide::modal', 'modal-edit-group')
        }, 
    }
}
</script>