import * as moment from 'moment'
import { v4 as uniqueIDentifier  } from 'uuid'
import store from '../../../store'

const formatEvents = ( events ) => { 
    const types = ['success', 'warning', 'error', 'processing', 'default' ] 
    events.forEach( event => {
        //ids
        event.idBackupLocation = event.backup ? event.backup.id : null
        event.idGroup = event.grupo.id
        event.idLocacion = event.locacion 
        event.idMontage = event.montaje ? event.montaje.id : null
        event.idTypeGroup = event.grupo.tipogrupo
        //resto
        event.createdate = event.createdate.substring(0, 10)         
        event.createdBy = formatCreatedBy( event.createdby )        
        event.day = moment( event.startDate ).date() 
        event.endTime = event.endTime.substring(0, 5)         
        event.nameBackup = findLocation( event.backup )    
        event.nameGroup = formatGroup( event.grupo )             
        event.nameLocation = findLocation( event.locacion )                  
        event.nameMontage = findMontage( event.montaje )                  
        event.nombreEvento = capitalizeFirstLetterString( event.nombreEvento )                  
        event.startTime = event.startTime.substring(0, 5) 
        event.type = types[Math.floor(Math.random() * 4) + 0 ]  //
        event.updatedBy = formatUpdatedBy( event.updatedby )        
        event.uuid = event.uuid ? event.uuid : uniqueIDentifier()   
        delete event.backup
        delete event.createdby
        delete event.deleted
        delete event.grupo
        delete event.locacion
        delete event.montaje
        delete event.montajebackup
        delete event.updatedby
    })    
    return events
}

const makeArrayEvents = ( events ) => {  
    let eventsData = []   
    let eventsCountMonth = events.length       
    events.map( event => {
        if( event ){
            const day = moment( event.startDate ).date()    
            // event.day = day
            if( !Array.isArray( eventsData[day] ) ) {
                eventsData[day] = []
            }
            //añado evento al día        
            eventsData[day].push(event)
            // eventsData[0] = []
            //ordeno los eventos por las horas       
            eventsData[day].sort( (a, b) => (a.startTime < b.startTime) ? -1 : ((a.startTime > b.startTime) ? 1 : 0))                
        }          
    })
    return { eventsData, eventsCountMonth }
}

const capitalizeFirstLetterString = ( string ) => {
    const cadena = string.toLowerCase()
    const arr = cadena.split(" ") 
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    
    }
    return arr.join(" ")
}

const findLocation = ( id ) => {
    const location =  store.state.calendar.locations.find( location => location.id == id )  
    return location ? `${ location.name }` : '--'    
}

const findMontage = ( id ) => {
    const montage =  store.state.calendar.montages.find( mont => mont.id == id )  
    return montage ? `${ montage.name }` : '--'    
}

const formatGroup = ( group ) => {    
    return group ? `${ group.name }` : '--'    
}

const formatCreatedBy = ( createdBy ) => {    
    return createdBy ? `${ createdBy.first_name } ${ createdBy.last_name }` : '--'    
}

const formatUpdatedBy = ( updatedBy ) => {    
    return updatedBy ? `${ updatedBy.first_name } ${ updatedBy.last_name }` : ''    
}

const createListEvents = ( events ) => {
    let listEvents = []            
    events.forEach( list => {            
        if( list ){
            listEvents.push(...list)
        }
    }) 
    return listEvents
}

const getEventsByLocations = ( idLocations, listEvents) => {
    let eventsByLocations = []        
    idLocations.forEach( idLoc => {
        const results = listEvents.filter( event => event.idLocacion === idLoc )
        if( results.length ){
            eventsByLocations.push(...results)
        }                               
    })
    const { eventsData } = makeArrayEvents( eventsByLocations )                      
    eventsByLocations = [...eventsData]
        
    return eventsByLocations 
}

const getEventsByTypeGroups = ( idTypeGroups, listEvents) => {
    let eventsByTypeGroups = []        
    idTypeGroups.forEach( idTypeGroup => {
        const results = listEvents.filter( event => event.idTypeGroup === idTypeGroup )
        if( results.length ){
            eventsByTypeGroups.push(...results)
        }                               
    })    
    const { eventsData } = makeArrayEvents( eventsByTypeGroups )                      
    eventsByTypeGroups = [...eventsData]
        
    return eventsByTypeGroups 
}

const getEventsByLocationsAndTypeGroups = ( idLocations, idTypeGroups, listEvents ) => {
    // array para guardar los eventos por grupos y locaciones  
    let eventsLocationsGroups = []  
    //busco los eventos por locaciones   
    idLocations.forEach( idLoc => {
        const results = listEvents.filter( event => event.idLocacion === idLoc )
        if( results.length ){
            eventsLocationsGroups.push(...results)
        }                               
    })
    //busco los eventos por grupos
    idTypeGroups.forEach( idTypeGroup => {
        const results = listEvents.filter( event => event.idTypeGroup === idTypeGroup )
        if( results.length ){
            eventsLocationsGroups.push(...results)
        }                               
    })    
    //del array resultante elimino los duplicados
    const eventos = [...new Map(eventsLocationsGroups.map( element => [element.id, element] )).values()] 
    console.log(eventos)
    //vuelvo a armar el arreglo de eventos adaptado para pintar en el calendario
    const { eventsData } = makeArrayEvents( eventos )                      
    eventsLocationsGroups = [...eventsData]
    return eventsLocationsGroups 
}

const createEventUpdated = ( event ) => {      
    const updatedBy = store.state.auth.user  
    event.day = new Date( event.startDate ).getDate() + 1
    event.nameBackup = namesFormatEventUpdate( event ).nameBackup 
    event.nameGroup = namesFormatEventUpdate( event ).nameGroup 
    event.nameLocation = namesFormatEventUpdate( event ).nameLocation 
    event.nameMontage = namesFormatEventUpdate( event ).nameMontage 
    event.updatedBy = `${updatedBy.name} ${updatedBy.lastname} XD`
    delete event.idLoc 
    delete event.fini 
    delete event.ffin 
    delete event.hini 
    delete event.hfin                       

    return event
}


const namesFormatEventUpdate = ( event ) => {
    const { idGroup, idLocacion, idBackupLocation, idMontage } = event
    
    let nameGroup = store.state.calendar.groups.find( group => group.id === idGroup )
    nameGroup =   nameGroup ? nameGroup.name : ''
    let nameLocation = store.state.calendar.locations.find( loc => loc.id === idLocacion )
    nameLocation =   nameLocation ? nameLocation.name : ''
    let nameBackup = store.state.calendar.backupLocations.find( bLoc => bLoc.id === idBackupLocation )    
    nameBackup =   nameBackup ? nameBackup.name : ''
    let nameMontage = store.state.calendar.montages.find( montage => montage.id === idMontage )    
    nameMontage =   nameMontage ? nameMontage.name : ''
    return { nameGroup, nameLocation, nameBackup, nameMontage }
}
 
export {
    formatEvents,
    makeArrayEvents,
    formatCreatedBy,
    capitalizeFirstLetterString,
    findLocation,
    createListEvents,
    getEventsByLocations,
    getEventsByTypeGroups,
    getEventsByLocationsAndTypeGroups,
    createEventUpdated
}