<template>
  <div>
    <Accessing v-if="isLoadingAuth"/>
    <div class="mx-1 my-1" v-if="!isLoadingAuth">      
      <b-tabs v-model="tabIndex">        
        <b-tab title="Calendario" active >
          <Calendar :tabIndex="tabIndex" @set-value-tab="setTabActive" />                
        </b-tab>
        <b-tab title="Eventos">
          <ListEvents/>
        </b-tab>
        <b-tab title="Grupos">
          <ListGroups/>
        </b-tab>    
      </b-tabs>  

      <CreateEvent/>
      <CreateGroup/>
      <EditEvent v-if="selectedEvent"/>
      <EditGroup v-if="selectedGroup"/>    
    </div>   
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import * as moment from 'moment'
import Accessing from "@/modules/calendar/components/calendar/Accessing"
import Calendar from "@/modules/calendar/components/calendar/Calendar"
import ListGroups from "@/modules/calendar/components/groups/ListGroups"
import ListEvents from "@/modules/calendar/components/events/ListEvents"
//components modals para los cruds
import CreateEvent from "@/modules/calendar/components/events/CreateEvent"
import EditEvent from "@/modules/calendar/components/events/EditEvent"
import CreateGroup from "@/modules/calendar/components/groups/CreateGroup"
import EditGroup from "@/modules/calendar/components/groups/EditGroup"

export default {
  name: 'CalendarView',  
  components : {
    Accessing,
    Calendar,    
    ListGroups,
    ListEvents,
    CreateEvent,
    EditEvent,
    CreateGroup,
    EditGroup  
  },
  async created() {    
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true )
    if( this.token ){
      // axios.defaults.headers.common['Authorization'] = 'Token '+ this.token
      // axios.defaults.baseURL = baseUrl
      // Vue.use(VueAxios, axios, baseUrlImg)      
      // await this.signInCalendarApp({})      
    }
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },  
  data() {
    return {
      tabIndex: 0,
    }
  },  
  methods: {        
    moment, 
    ...mapActions('auth',['signInCalendarApp']),
    setTabActive( value ){
      this.tabIndex = value
    },     
  },  
  computed:{
    ...mapState('auth',['token','isLoadingAuth','idUser']),
    ...mapState('calendar',['selectedDate','selectedGroup','selectedEvent']),
  }    
}
</script>

