import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const showAlertMessage = (title = 'Ok', icon = 'BellIcon', text, variant = 'success', timeout = '3000', position = 'bottom-right') => {
    Vue.$toast({
        component: ToastificationContent,
        props: { title, icon, text, variant },
    }, { timeout, position })
}

export { showAlertMessage }