
<template>
    <div>        
        <b-row >            
            <!-- v-if="dateIsSameOrAfter" -->
            <b-col >              
              <div class="float-right">
                <b-button variant="primary" class="btn-icon mr-1" @click="handlerCreateGroup" v-if="idUser">
                    Crear grupo
                </b-button> 
                <b-button variant="primary" class="btn-icon" @click="handlerCreateEvent" v-if="idUser">
                    Crear evento
                </b-button> 
              </div>                            
            </b-col>           
        </b-row>
        <b-row>                             
            <b-col md="3">
                <strong>Hotel y sus locaciones</strong>
                <v-select                    
                    v-model="hotelsSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    :options="hotelsWithAll"
                    label="name"
                >
                    <template #option="{ name }">
                    <feather-icon
                        icon="BriefcaseIcon"
                        size="16"
                        class="align-middle mr-25"
                    />
                    <span> {{ name }}</span>
                    </template>
                </v-select> 
            </b-col>
            <b-col md="4">
                <strong>Filtrar eventos por locaciones</strong>
                <v-select                    
                    v-model="locationsSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    :options="locationsWithAll"
                    label="name"
                >
                    <template #option="{ name }">
                    <feather-icon
                        icon="MapPinIcon"
                        size="16"
                        class="align-middle mr-25"
                    />
                    <span> {{ name }}</span>
                    </template>
                </v-select> 
                
            </b-col>
            <b-col md="3">
                <strong>Filtrar eventos por tipo de grupo</strong>
                <v-select                    
                    v-model="groupTypesSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    :options="groupTypesWithAll"
                    label="tipogruponame"
                >
                    <template #option="{ tipogruponame }">
                    <feather-icon
                        icon="UsersIcon"
                        size="16"
                        class="align-middle mr-25"
                    />
                    <span> {{ tipogruponame }}</span>
                    </template>
                </v-select> 
                
            </b-col>
        </b-row>            
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import vSelect from 'vue-select'
import * as moment from 'moment'
import { currentDate } from '@/modules/calendar/helpers/currentDate'

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            hotelFilter: 0,  
            hotelsSelected: [ { name: 'Todos', id: 0, } ],  
            locationsSelected: [ { name: 'Todos', id: 0, } ],  
            groupTypesSelected: [ { tipogruponame: 'Todos', id: 0, } ],                               
        }
    },
    computed:{
        ...mapState('auth', ['idUser']),
        ...mapState('calendar', ['selectedDate','hotels']),

        ...mapGetters('calendar',['hotelsWithAll','locationsWithAll','groupTypesWithAll']),        
        // currentDate(){
        //     return currentDate()
        // },
        dateIsSameOrAfter(){
            const fechaActual = currentDate()
            const fechaSeleccionada = moment(this.selectedDate).format('YYYY-MM-DD')                
            return moment( fechaSeleccionada ).isSameOrAfter( fechaActual, 'day') 
        }
    },
    methods:{
        moment,
        ...mapMutations('calendar',['setIdHotelsFilter','setIdLocationsFilter','setIdTypeGroupsFilter']),
        setValueHotel(value){                     
            this.hotelFilter = value   
            console.log(this.listLocations.length)                  
        },
        handlerCreateEvent(){
		    this.$root.$emit('bv::show::modal', 'modal-create-event')                                 
        },
        handlerCreateGroup(){
            this.$root.$emit('bv::show::modal', 'modal-create-group')                                 
        },
        getIdHotels(){
            return this.hotelsSelected.map( location => location.id )
        },
        getIdLocations(){
            return this.locationsSelected.map( location => location.id )
        },  
        getIdTypeGroups(){
            return this.groupTypesSelected.map( location => location.id )
        },              
    },
    watch:{
        hotelsSelected () { 
            this.setIdHotelsFilter( this.getIdHotels() )                             
        },
        locationsSelected () { 
            this.setIdLocationsFilter( this.getIdLocations() )                             
        },
        groupTypesSelected(){
            this.setIdTypeGroupsFilter( this.getIdTypeGroups() )            
        }
    }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>