<template>
    <b-card tag="article" no-body>
        <b-card-body>
          <b-card-title>
            <b-row>
              <b-col>
                <strong>{{group.name}}</strong>                
              </b-col>                
              <b-col>
                <div class="float-right">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="btn-icon btn-sm"
                      @click="handlerEditGroup"
                      v-if="idUser"
                  >
                      <feather-icon icon="Edit2Icon" />
                  </b-button>                               
                </div>
              </b-col>                

            </b-row>
        </b-card-title>                  
          <b-row>
            <b-col>
                <strong>Llegada: {{group.arrivaldate}} </strong>
            </b-col>
            <b-col>
                <strong>Salida:  {{group.departuredate}} </strong>
            </b-col>
            <b-col>
                <small class="text-body">
                    <strong>AD: </strong> <b-badge variant="warning"> {{group.adult}} </b-badge>
                    <strong>INF: </strong> <b-badge variant="warning"> {{group.infant}} </b-badge>
                    <strong>NI: </strong> <b-badge variant="warning"> {{group.child}} </b-badge>
                </small>                 
            </b-col>  
            <b-col>
                <strong>Coordinador: {{group.coordinador}} </strong>
            </b-col>          
            <b-col>
                <strong>Hotel: {{group.hotel}}</strong>
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col>
                <app-collapse type="border">
                    <app-collapse-item title="Ver locaciones">
                    <!-- <pre>{{group.events}}</pre> -->
                    <ListEventsByGroup :events="group.events" />
                    </app-collapse-item>            
                </app-collapse>
            </b-col> 
        </b-row>
                  
        </b-card-body>
    </b-card>
    
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ListEventsByGroup from "@/modules/calendar/components/groups/ListEventsByGroup"

export default {
    directives: {
        Ripple,
    },
    components:{
        AppCollapse,
        AppCollapseItem,
        ListEventsByGroup,
    },
    props:{
        group:{
            type: Object,
            required: true
        }
    },      
    computed:{
        ...mapState('auth', ['idUser']),
        ...mapState('calendar', ['selectedDate','groups']),                    
    },
    methods:{
        ...mapMutations('calendar',['setSelectedGroup']),  
       async handlerEditGroup()  {
            // const group = { ...this.group } //rompo la referencia con spread
            // delete group.events
            await this.setSelectedGroup( this.group )
			this.$root.$emit('bv::show::modal', 'modal-edit-group')                                 
        }
    }
}
</script>