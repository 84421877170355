import { v4 as uniqueIDentifier  } from 'uuid'
import store from '../store'
import { formatCreatedBy, capitalizeFirstLetterString, findLocation } from './eventsHelper'

const formatGroups = ( groups ) => {      
    groups.forEach( group => {                 
        group.coordinador = findCoordinator( group.coordinador ) 
        group.createdBy = formatCreatedBy( group.createdby )
        group.events = formatEventsInGroup( group.calendario ),        
        group.hotel = findHotel( group.grouphotel ) 
        group.idCoordinador = group.coordinador 
        group.idHotel = group.grouphotel 
        group.name = capitalizeFirstLetterString( group.name ) 
        group.uuid = group.uuid ? group.uuid : uniqueIDentifier(),        
        delete group.calendario
        delete group.conventsoft
        delete group.createdby
        delete group.deleted
        delete group.grouphotel
    })     
    return groups
}

const formatEventsInGroup = ( events ) => {       
    events.forEach( event => {
        //ids
        event.idBackupLocation = event.backup ? event.backup.id : null
        event.idGroup = event.grupo ? event.grupo.id : event.idGroup 
        event.idLocacion = event.locacion 
        event.idMontage = event.montaje ? event.montaje.id : null
        //resto
        event.createdate = event.createdate.substring(0, 10)         
        event.createdBy = formatCreatedBy( event.createdby ) 
        event.endTime = event.endTime.substring(0, 5)                 
        event.montaje = formatMontaje( event.montaje )                                            
        event.nameBackup = findLocation( event.backup )    
        event.nameGroup = event.grupo ? event.grupo.name : ''         
        event.nameLocation = findLocation( event.locacion )                            
        event.nombreEvento = capitalizeFirstLetterString( event.nombreEvento )                  
        event.startTime = event.startTime.substring(0, 5) 
        event.updatedBy = event.updatedby ? `${ event.updatedby.first_name } ${ event.updatedby.last_name }` : ''      
        event.uuid = uniqueIDentifier()           
        delete event.backup   
        delete event.createdby
        delete event.updatedby
        delete event.deleted        
        delete event.grupo   
        delete event.montajebackup
    })    
    return events
}
const formatMontaje = ( montaje ) =>{
    return montaje ? montaje.name : '--'
}
const findCoordinator = ( id ) => {
    const coordinator =  store.state.coordinators.find( coordinator => coordinator.id == id )  
    return coordinator ? `${ coordinator.name } ${ coordinator.lastname }` : ''    
}

const findHotel = ( id ) => {
    if( id ){
        const hotel =  store.state.hotels.find( hotel => hotel.id == id )  
        return hotel ? `${ hotel.name }` : '--'    
    }
    return '--'
}

export { formatGroups }