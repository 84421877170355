import { 
    createListEvents,
    getEventsByLocations,
    getEventsByTypeGroups,
    getEventsByLocationsAndTypeGroups
} from './../helpers/eventsHelper'

export function locationsWithAll( state ) {
    const locations = [...state.locations] //locations del state, spread operator
    const idHotels = state.filters.idHotels
    const idHotelsLength = idHotels.length

    if( idHotelsLength == 1 && idHotels[0] == 0 ){                   
        return state.locations         
    }
    if( idHotelsLength == 0 ){        
        return state.locations         
    }
    if( idHotelsLength > 0 ){        
        let locationsByHotels = []        
        idHotels.forEach( idHotel => {
            const results = locations.filter( location => location.idHotel === idHotel )
            if( results.length ){
                locationsByHotels.push(...results)
            }                               
        })        
        return locationsByHotels      
    }

    return state.locations
}

export function locationsWithOutAll( state ) {
    return state.locations.filter( location => location.id !== 0 )        
}

export function groupTypesWithAll( state ) {
    return state.groupTypes        
}

export function groupTypesWithOutAll( state ) {
    return state.groupTypes.filter( location => location.id !== 0 )        
}

export function hotelsWithAll( state ) {
    return state.hotels        
}

export function hotelsWithOutAll( state ) {
    return state.hotels.filter( hotel => hotel.id !== 0 )        
}

export function groupsFiltered( state ) {
    let groups = state.groups
    //con esto filtro los resultado por el name del group, no es case sensitive
    if( state.filters.query.length > 1 ){
        groups = groups.filter( group => group.name.toLowerCase().includes( state.filters.query.toLowerCase() ) )        
    }
    return groups
}

export function eventsFiltered( state ) {
    const events = [...state.eventsData] //eventos del state, spread operator
    const listEvents = createListEvents( events )                      
    //array state idLocations, idTypeGroups
    const idLocations = state.filters.idLocations
    const idTypeGroups = state.filters.idTypeGroups
    //longitud de arrays
    const idLocLength = idLocations.length
    const idTypeGLength = idTypeGroups.length
    
    if( idLocLength == 1 && idLocations[0] == 0 &&  idTypeGLength == 1 && idTypeGroups[0] == 0 ){
        // console.log('aqui, default')
        return state.eventsData
    }

    if( idLocLength == 0  && idTypeGLength == 0 ){
        // console.log('aqui, long 0 locaciones')        
        return state.eventsData
    }
    
    if( idLocLength > 0 && idTypeGLength == 1 && idTypeGroups[0] == 0 ){
        // console.log('aqui, locaciones')
        return getEventsByLocations(idLocations, listEvents)                        
    }

    if( idTypeGLength > 0 && idLocLength == 1 && idLocations[0] == 0 ){
        // console.log('aqui, grupos')
        return getEventsByTypeGroups(idTypeGroups, listEvents)                                
    }
    
    if( idLocLength > 0 && idTypeGLength > 0 ){
        return getEventsByLocationsAndTypeGroups(idLocations, idTypeGroups, listEvents)        
    }
    return state.eventsData    
}