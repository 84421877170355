import Vue from 'vue'
import { showAlertMessage,  } from '../helpers/showAlertMessage'
import { consoleMsgFinally } from '../helpers/consoleMsgFinally'

// import router from './../../router/'

export async function signInCalendarApp(context, user){    
    try {
        context.commit('auth/setLoadingAuth', true, { root: true} )
        const response =  await Vue.axios({            
            method: 'POST',
            url:'/weblogin/',
            data: user
        })        
        //desestructuración de lo que me regresa en el respose payload      
        const {id, name, lastname } = response && response.data
       
        user.idUser = id
        user.name = name
        user.lastname = lastname
        delete user.password //del user elimino el password, para guardarlo en el state                       
        context.commit('setUser', user )
                                                       
    } catch (error) { 
        if(error.response){
            console.log(error.response.statusText)
            if( error.response.statusText == 'Bad Request' ){
                showAlertMessage(`${error.response.statusText || 'No existe usuario' } ${': datos incorrectos'}`,'InfoIcon', '😪😪 Usuario o Contraseña incorrectos, o el usuario no existe', 'danger', 5000, 'bottom-right')                 
            }
            if( error.response.statusText == 'Unauthorized' ){
                showAlertMessage(`${error.response.statusText || 'No existe usuario' } ${': acceso invalido'}`,'InfoIcon', '😪😪 Credenciales de acceso inválidos', 'danger', 5000, 'bottom-right')
            }            
        } 
    } finally {
        context.commit('auth/setLoadingAuth', false, { root: true} ) //se terminar de mostrar el loader          
        consoleMsgFinally('auth/signIn','Peticion de datos de usuario terminada')
    }
}