export const currentDate = () => {
    let date = new Date();
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);
    // current month
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
    let year = date.getFullYear()

    return year + "-" + month + "-" + day
}