<template>
    <div class="table-responsive">        
            <b-alert show variant="danger" v-if="!events.length" >
                <div class="alert-body text-center">
                    😣 <span>No hay eventos para este grupo</span>
                </div>
            </b-alert>                       
            <table class="table" v-if="events.length">
                <thead class="thead-dark">
                    <tr style="font-size: .9em">
                        <th scope="col">Evento</th>                            
                        <th scope="col">Fecha hora inicio</th>
                        <th scope="col">Fecha hora fin</th>
                        <th scope="col">Locación</th>
                        <th scope="col">Loc. Backup</th>
                        <th scope="col">Montaje</th>

                        <th scope="col">Creado por</th>
                        <!-- <th scope="col">Montaje</th> -->
                        <th scope="col">Adultos </th>
                        <th scope="col">Niños </th>
                        <th scope="col">Infantes </th>
                        <th scope="col" v-if="idUser">
                            <feather-icon icon="SettingsIcon" /> Acciones
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="event in eventsSort" :key="event.uuid" style="font-size: 1em">
                        <!-- <th scope="row">{{index + 1 }}</th>                             -->
                        <th scope="row">{{event.nombreEvento }}</th>                            
                        <td>{{ event.startDate }} {{ event.startTime }} </td>
                        <td>{{ event.endDate  }} {{ event.endTime }}</td>
                        <td>{{ event.nameLocation}}</td>
                        <td>{{ event.nameBackup}}</td>
                        <td>{{ event.montaje}}</td>
                        <td>{{ event.createdBy}}</td>
                        
                        <!-- <td>{{ event.backup }}</td> -->
                        <!-- <td>{{ event.montaje ? event.montaje.name : ''}}</td>                             -->
                        <td>{{event.adult}} </td>
                        <td>{{event.child}} </td>
                        <td> {{event.infant}}</td> 
                        <td v-if="idUser"> 
                            <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="success"
                                    class="btn-icon btn-sm"
                                    @click="handlerEditEvent(event)"
                                >
                                <feather-icon icon="Edit2Icon" />
                            </b-button>
                            &nbsp;
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="danger"
                                    class="btn-icon btn-sm"
                                >
                                <feather-icon icon="XIcon" />
                            </b-button>
                        </td>
                    </tr>                      
                </tbody>
            </table>   
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default { 
    directives: {
        Ripple,
    },       
    props:{
        events:{
            type: Array,
            required: true
        }
    }, 
    computed: {
        ...mapState('auth',['idUser']),
        // ordenamiento de los datos del calendario del grupo for fecha startDate y startTime 
        eventsSort(){               
            const array = this.events                        
            return array.sort(function(a,b){
                let fecha1 = new Date(`${b.startDate} ${b.startTime}`)
                let fecha2 = new Date(`${a.startDate} ${a.startTime}`)                                
                return  fecha2.getTime() - fecha1.getTime()
            })            
        }                                              
    },  
    methods:{
        ...mapMutations('calendar', ['setSelectedEvent']),
        async handlerEditEvent( event ){
            // const evento = {...event } //rompo la referencia con spread
            event.tab = 'groups'
            await this.setSelectedEvent( event )
			this.$root.$emit('bv::show::modal', 'modal-edit-event')                                 
        }
    }
}
</script>