
<template>
    <b-card tag="article" no-body >                      
        <b-card-body>
            <b-card-title v-if="idUser">
                <b-row>
                    <b-col>{{event.nombreEvento}}
                        <div class="float-right">
                            <b-button                                
                                variant="success"
                                class="btn-icon btn-sm"
                                @click="handlerEditEvent"
                            >
                                <feather-icon icon="Edit2Icon" />
                            </b-button>
                            &nbsp;
                            <b-button                                
                                variant="danger"
                                class="btn-icon btn-sm"
                                @click="handlerDeletEvent"
                            >
                            <feather-icon icon="XIcon" />
                            </b-button>              
                        </div>
                    </b-col>
                    
                </b-row>
            </b-card-title>
            <b-media no-body>
                <b-media-aside
                    vertical-align="center" class="mr-50"
                >
                    <b-avatar
                        variant="primary"                            
                        :text="event.createdBy.substring(0,2).toUpperCase()"
                    />
                </b-media-aside>
                <b-media-body>
                    <strong class="mr-50">Creado por:</strong>
                    <small>
                    <b-link class="text-body">{{event.createdBy}}</b-link>
                    </small>
                    <strong class="ml-75 mr-50">| Fecha:</strong>
                    <small class="text-body"> {{event.createdate}}</small> 
                    <br>
                    <small class="text-body">
                        <strong>AD: </strong> <b-badge variant="warning"> {{event.adult}} </b-badge>
                        <strong>INF: </strong> <b-badge variant="warning"> {{event.infant}} </b-badge>
                        <strong>NI: </strong> <b-badge variant="warning"> {{event.child}} </b-badge>
                    </small>                          
                </b-media-body>
            </b-media>
            <div class="my-1 py-25">
                <strong>Grupo: </strong>{{event.nameGroup}} <br>
                <b-row>
                    <b-col>
                        <strong>Inicio:</strong> {{event.startDate}} {{event.startTime}}
                    </b-col>
                    <b-col>
                        <strong>Fin: </strong> {{event.endDate}} {{event.endTime}}
                    </b-col>
                </b-row> 
                <strong>Locación: </strong>{{event.nameLocation}}
            </div>                    
            <hr>
            <div class="d-flex justify-content-between align-items-center">
            <span>
                <div class="d-flex align-items-center text-body">
                <feather-icon
                    icon="MapPinIcon"
                    class="mr-50"
                />
                <strong> Backup</strong>
                </div>
            </span>
            <span>
                <strong>Actualizado por:</strong> {{event.updatedBy}}
            </span>
            </div>
        </b-card-body>
        
    </b-card>                     
</template>

<script>
import Swal from 'sweetalert2'
import * as moment from 'moment'
import { mapState, mapActions, mapMutations } from 'vuex'
import { showAlertMessage } from '@/modules/calendar/helpers/showNotification'

export default {
  
    props:{
        event:{
            type: Object,
            required: true
        }
    },    
    computed:{
        ...mapState('auth', ['idUser']),
        ...mapState('calendar', ['eventsSelectedDate','selectedDate']),                    
    },
    methods:{
        moment,
        ...mapActions('calendar',['fetchGroups','deleteEvent']), 
        ...mapMutations('calendar', ['setSelectedEvent','removeEventInMonth']),
        async handlerEditEvent(){
            const event = {...this.event } //rompo la referencia con spread
            event.tab = 'events'
            await this.setSelectedEvent( event )            
			this.$root.$emit('bv::show::modal', 'modal-edit-event')                                 
        },
        async handlerDeletEvent(){
            const event = {...this.event } //rompo la referencia con spread
            const { isConfirmed } = await Swal.fire({
                title: `¿Está seguro de borrar?`,
                html:`Una vez borrado no podrá recuperar al evento <strong>${event.nombreEvento}</strong>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí borrar',
                cancelButtonText: 'Cancelar',                
            })                 
            if( isConfirmed ){                 
                const { ok, message } =  await this.deleteEvent( event )                
                if( ok ){
                    const { day, uuid } = event                    
                    showAlertMessage( 'Ok', 'InfoIcon', message, 'success', 4000, 'top-right')
                    this.removeEventInMonth( { day, uuid } )                    
                }
                if( !ok ){
                    showAlertMessage( 'Error', 'InfoIcon', message, 'danger', 4000, 'top-right')
                }
                    
            }            
        }        
    }
}
</script>