
<template>
    <div>
        <b-row class="my-1">
            <b-col md="6">   
                <div class="float-right">
                    <h4>Fecha: {{ moment(selectedDate).format('DD/MM/YYYY') }}</h4>                
                </div>                              
            </b-col>
            <b-col>
                <div class="float-right" v-if="idUser">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        @click="handlerCreateEvent"
                    >
                        Crear evento
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!eventsSelectedDate.length">
                <b-alert show variant="danger" class="mt-2">
                    <div class="alert-body text-center"> 😣
                        <span>No se han encontrado eventos para esta fecha</span>
                    </div>
                </b-alert>
            </b-col>            
            <b-col md="4" v-for="event in eventsSelectedDate" :key="event.uuid">
                <b-card tag="article" no-body >                
                    <Event :event="event" />
                </b-card>
            </b-col>                               
        </b-row>
    </div>                      
</template>

<script>
import { mapState } from 'vuex'
import * as moment from 'moment'
import Event from "@/modules/calendar/components/events/Event"
import Ripple from 'vue-ripple-directive'

export default {  
    directives: {
        Ripple,
    },  
    components:{
        Event,

    },
    computed:{
        ...mapState('auth',['idUser']),
        ...mapState('calendar', ['eventsSelectedDate','selectedDate']),                    
    },
    methods:{
        moment,
        handlerCreateEvent(){
			this.$root.$emit('bv::show::modal', 'modal-create-event')                                 
        }
    }   
}
</script>
