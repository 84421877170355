<template>   
    <!--  -->
    <b-modal id="modal-edit-event" :title="'Actualizar: '+ selectedEvent.nombreEvento" size="lg"
      no-close-on-backdrop hide-footer  hide-header-close        
    >
        <b-card-text>
            <ValidationObserver ref="formEditEvent" tag="form">
                <b-form @submit.prevent="handlerUpdateEvent">
                    <b-row>
                        <b-col md="6">
                            <ValidationProvider name="locacion" rules="required">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Locación *</strong>
                                        <b-form-select
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            v-model="selectedEvent.idLocacion" 
                                        >
                                        <option :value="null">Seleccione locación</option>
                                        <option
                                            v-for="(location, index) in locationsWithOutAll"
                                            :key="index"
                                            :value="location.id"
                                        >{{location.name}}</option>
                                        </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider rules="required" name="nombre de evento">
                                <b-form-group slot-scope="{ valid, errors }">
                                <strong>Nombre evento *</strong>
                                <b-input
                                    placeholder="Ingrese nombre del evento"
                                    v-model="selectedEvent.nombreEvento"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider rules="required" name="fecha inicio">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Fecha inicio * </strong>                   
                                    <b-input                                        
                                        type="date"                                                        
                                        v-model="selectedEvent.startDate"            
                                        :min="currentDateInput"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="text-center">
                                <b-form-group>
                                    <strong>Hora inicio * </strong>
                                    
                                    <antTimePicker
                                        @change="onHourChangeInit"
                                        :minuteStep="5"
                                        :value="moment(selectedEvent.startTime,'HH:mm')"
                                        format="HH:mm"
                                        :v-model="moment(selectedEvent.startTime,'HH:mm')"
                                    ></antTimePicker> 
                                </b-form-group>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider rules="required" name="fecha fin">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Fecha fin *</strong>
                                    <b-input                                        
                                        type="date"                        
                                        v-model="selectedEvent.endDate"
                                        :min="currentDateInput"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-input>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="text-center">
                                <b-form-group>
                                    <strong>Hora fin* </strong>     
                                    <antTimePicker
                                        @change="onHourChangeEnd"
                                        :minuteStep="5"
                                        :value="moment(selectedEvent.endTime,'HH:mm')"
                                        :v-model="moment(selectedEvent.endTime,'HH:mm')"
                                        :defaultValue="moment('11:00','HH:mm')"
                                        format="HH:mm"
                                    ></antTimePicker>
                                </b-form-group>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="montaje" rules="">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Montaje</strong>
                                    <b-form-select
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="selectedEvent.idMontage" 
                                    >
                                    <option :value="null">Seleccione montaje</option>
                                    <option
                                        v-for="(montage, index) in montages"
                                        :key="index"
                                        :value="montage.id"
                                    >{{montage.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="backup" rules="">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Backup</strong>
                                    <b-form-select
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="selectedEvent.idBackupLocation" 
                                    >
                                    <option :value="null">Seleccione backup</option>
                                    <option
                                        v-for="(bLocation, index) in backupLocations"
                                        :key="index"
                                        :value="bLocation.id"
                                    >{{bLocation.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="group" rules="required">
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Grupo</strong>
                                    <b-form-select
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="selectedEvent.idGroup" 
                                    >
                                    <option :value="null">Seleccione grupo</option>
                                    <option
                                        v-for="(group, index ) in groups"
                                        :key="index"
                                        :value="group.id"
                                    >{{group.name}}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider rules="required" name="adultos">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Adultos*</strong>                            
                                        <b-input                                            
                                            v-model="selectedEvent.adult"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                        <b-col md="6">
                            <ValidationProvider rules="required" name="niños">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Niños*</strong>                            
                                        <b-input                                            
                                            v-model="selectedEvent.child"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                        <b-col md="6">
                            <ValidationProvider rules="required" name="infantes">                
                                <b-form-group slot-scope="{ valid, errors }">
                                    <strong>Infantes*</strong>                            
                                        <b-input                                            
                                            v-model="selectedEvent.infant"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @keypress="onlyNumber" 
                                        ></b-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> 
                    </b-row>                    
                    <b-row>
                        <b-col md="6">
                            <b-button variant="danger" @click="closeModal">Cerrar</b-button>
                        </b-col>
                        <b-col md="6">                        
                            <b-button type="submit" variant="success" class="float-right" >Actualizar evento</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>                          
        </b-card-text>
    </b-modal>     
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import * as moment from 'moment'
import { currentDate } from '@/modules/calendar/helpers/currentDate'
import { toJson } from '@/modules/calendar/helpers/toJson'
import { showAlertMessage } from '@/modules/calendar/helpers/showNotification'

export default {
    directives: {
        Ripple,
    },
    components:{
    },          
    computed:{
        ...mapState('auth',['idUser']),        
        ...mapState('calendar', ['selectedEvent','groups','backupLocations','montages']), 
        ...mapGetters('calendar',['locationsWithOutAll']),
        currentDateInput(){
            return currentDate()
        },  
        computedForm(){
            return this.$refs.formEditEvent
        }                   
    },
    methods:{
        moment,
        ...mapActions('calendar',['validateEvent','updateEvent']), 
        onlyNumber(event){      
            let keyCode = (event.keyCode ? event.keyCode : event.which)
            const codesAllowed = [ 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if ( !respuesta ) {                                       
				event.preventDefault()
			}
        }, 
        onHourChangeInit( hour ){
            if( hour && hour !== ''){
                this.selectedEvent.startTime = hour.format("HH:mm:ss")               
            }                   
        },
        onHourChangeEnd( hour ){
            if( hour && hour !== ''){
                this.selectedEvent.endTime = hour.format("HH:mm:ss")               
            }                     
        },
        async handlerUpdateEvent(){

            const event = toJson( { ...this.selectedEvent } ) //break referece with spread operator
            const isValidForm = await this.validateFields() //validate required fields          
            const isValidDates = this.validateDates( event ) //validate dates 
            
            if ( !isValidForm ) return   
            
            if( !isValidDates ){
                const text = `La fecha de inicio no debe ser menor a la fecha final 😪😪`
                showAlertMessage( 'Fechas invalidas', 'InfoIcon', text, 'danger', 4000, 'top-right')
                return
            }
            const payloadEvent = this.createPayloadEvent( event )
                        
            const { ok: okValidate, message } = await this.validateEvent( payloadEvent )
            
            if( !okValidate ){
                showAlertMessage( 'Error', 'InfoIcon', message, 'danger', 4000, 'top-right')
            }
            //if okValidate, save event
            if( okValidate ){
                const { ok: savedEvent, message } = await this.updateEvent( payloadEvent )
                if( savedEvent ){  
                    this.closeModal()
                    showAlertMessage( 'Ok', 'InfoIcon', message, 'success', 4000, 'top-right')
                }
                if( !savedEvent ){
                    showAlertMessage( 'Error', 'InfoIcon', message, 'danger', 4000, 'top-right')
                }          
            }
            
        },        
        validateFields(){
            return this.computedForm.validate()
        },
        validateDates( event ){
            const { endDate, startDate } = event // desestructuro event
            if( endDate != "" && startDate != "" ){
                return moment( endDate ).isSameOrAfter( startDate, 'day')             
            } 
            return true            
        },
        createPayloadEvent( event ){
            event.idBackupLocation = event.idBackupLocation ? event.idBackupLocation : null
            event.backup = event.backup ? event.backup : null
            event.grupo = event.idGroup 
            event.locacion = event.idLocacion ? event.idLocacion : null
            event.montaje = event.idMontage ? event.idMontage : null
            event.updatedby =  this.idUser,
            //campos para validar evento
            event.idLoc = event.idLocacion ? event.idLocacion : null 
            event.fini = event.startDate 
            event.ffin = event.endDate 
            event.hini = event.startTime 
            event.hfin =  event.endTime
                   
            if( !event.backup ){
                delete event.backup                
            }
            if( !event.montaje ){
                delete event.montaje                
            }
                       
            return event
        },
        
        closeModal(){
            this.$root.$emit('bv::hide::modal', 'modal-edit-event')
        },    
    }
}
</script>