import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import appConfig from '@/modules/appConfig/store'
import calendar from '@/modules/calendar/store'
import auth from '@/modules/auth/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appConfig,
    auth,
    calendar
  },
  strict: process.env.DEV,
})