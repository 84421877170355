<template>
<ValidationObserver ref="observer" v-slot="{ invalid }">                      
        <ValidationProvider rules="min:4" name="buscador">
            <b-form-group class="mb-1" slot-scope="{ valid, errors }">
                <b-input-group>
                    <b-form-input
                        v-model="filters.query"
                        placeholder="Buscar grupo"
                        @input="setFilterGroups"
                        :state="errors[0] ? false : (valid ? true : null)"
                    />
                    <b-input-group-append>
                    <b-button variant="primary" @click="searchGroup" :disabled="invalid" >
                        <feather-icon icon="SearchIcon" /> 
                    </b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback>
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                </b-input-group> 
                <!-- {{invalid}} {{filters.query.length > 3}}              -->
            </b-form-group>             
        </ValidationProvider>                        
    </ValidationObserver>
       
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { showAlertMessage } from '@/modules/calendar/helpers/showNotification'

export default {              
    computed:{
        ...mapState('calendar', ['filters']),                            
    },
    methods:{
        ...mapActions('calendar',['fetchGroups','fetchGroupsByName']),  
        ...mapMutations('calendar',['setFilterGroups']),   
         async searchGroup(){
            if( this.filters.query != "" && this.filters.query.length > 3 ){                  
                await this.fetchGroupsByName( this.filters.query )
            } else {
                const text = `😪😪 Ingrese mas de 3 caracteres para la busqueda`                
				showAlertMessage( 'Falta caracteres', 'InfoIcon', text, 'danger', 4000, 'bottom-right')
            }
        }     
    }
}
</script>