<template>
    <b-card tag="article" no-body>
        <b-card-body>                             
          <Filters />                               
          <div class="text-center mt-2 mb-2" v-if="isLoadingCalendar">
            <div>
              <strong>Cargando eventos</strong>              
            </div>
            <b-spinner label="Loading..." variant="info" />
          </div>
          <div class="float-right">
            <h4>Fecha: {{ moment(selectedDate).format('DD/MM/YYYY') }}</h4>
          </div>
          <br>
          <antConfigProvider :locale="locale" v-if="!isLoadingCalendar">

              <antCalendar @select="handlerEvent" @panelChange="onPanelChange" :value="selectedDate" :mode="modeCalendar">          
                  <ul slot="dateCellRender" slot-scope="value" class="events" >
                      <li v-for="event in getListData(value)" :key="event.id">
                        <antBadge :status="event.type" :text="`${event.startTime} - 
                          ${event.nombreEvento}`"/>              
                      </li>
                  </ul>                    
                  <template slot="monthCellRender" slot-scope="value">
                    <div v-if="getMonthData(value)" class="notes-month">
                    <section>{{ getMonthData(value) }}</section>
                    <span>Eventos</span>
                    </div>
                  </template>
              </antCalendar>
          </antConfigProvider>                     
        </b-card-body>        
    </b-card>    
</template>

<script>

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import * as moment from 'moment'
import esEs from 'ant-design-vue/lib/locale-provider/es_ES'
import Filters from '@/modules/calendar/components/calendar/Filters'
export default {
  name: 'CalendarGrid',
  components:{
    Filters
  },
  props:{
    tabIndex:{
      type : Number,
      required: true
    }
  },  
  async created() {  
    this.$store.commit('calendar/setSelectedDate', moment() )    
    await this.fetchHotels()
    await this.fetchGroupTypes()
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true )
    const payload = { 
      start: moment().format("YYYY-MM-01"),
      end: moment().endOf('month').format("YYYY-MM-DD"),
      currentDayMonth: Number(moment().format("DD") )
    }    
    await this.fetchEvents( payload ) 
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },  
  data() {
    return {
      locale: esEs,
      makeRequest: false               
    }
  },
  computed:{
    ...mapState('calendar', ['modeCalendar','eventsForYear','selectedDate','isLoadingCalendar','hotels']),  
    ...mapGetters('calendar',['eventsFiltered'])                  
  },
  methods: {
    ...mapActions('calendar',['fetchEvents','fetchCountEventsForYear','fetchHotels','fetchGroupTypes']),
    ...mapMutations('calendar',['setModeCalendar','setSelectedDate','setEventsSelectedDate']),
    moment,
    getListData(value) {
      let listData
      if( value.month() === this.selectedDate.month() ){
        const day = value.date() 
        listData = this.eventsFiltered[day]       
      }
      return listData || []
    },
    getMonthData(value) {
      //entro aqui cuando se cambia      
      return this.eventsForYear[ value.month() ]            
    },
    async onPanelChange( date, mode ){       
      const firstDay = moment( date._d ).format("YYYY-MM-01")       
      //si el año del panel es diferente del año de la fecha selecionada
      if( date.year() !== this.selectedDate.year() ||  date.month() !== this.selectedDate.month() ){ 
        // await this.fetchCountEventsForYear( {  year: date.year() } )
        this.makeRequest = true
      }      
      this.setModeCalendar( mode )      
      this.setSelectedDate( moment( firstDay ) )      
      if( this.makeRequest ){                         
        const payload = {
          start: moment( date._d ).format("YYYY-MM-01"),
          end: moment( date._d ).endOf('month').format("YYYY-MM-DD"),
          currentDayMonth: 1 
        }            
        await this.fetchEvents( payload )
        this.makeRequest = false
      }        
    },
    async handlerEvent ( date ) {         
      //obtengo fecha (día del mes) y busco los eventos de ese día
      const dayInMonth = date.date()
      const eventsInDay = this.eventsFiltered[dayInMonth] 
      const eventsDay = eventsInDay ? [...eventsInDay] : []
      this.setEventsSelectedDate( eventsDay )       
      //selectedDate se actualiza a la fecha obtenida      
      this.setSelectedDate( date )
      if ( this.modeCalendar === 'month' ) {
        //si hay eventos para este día abro tab para detalles de eventos
        if( eventsInDay ){                                                       
        //   this.tabIndex = 1    
          this.$emit('set-value-tab', 1)  
        }
        //sino hay eventos para este día, abro modal de creación de evento
        if( !eventsInDay && !this.makeRequest ){          
			    this.$root.$emit('bv::show::modal', 'modal-create-event')                                 
        }
      } else {        
        this.setModeCalendar('month')
      }
    },       
  },      
}
</script>


<style scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}


</style>
