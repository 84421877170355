<template>
<div>
  <b-row>
    <b-col md="12" class="text-center">
      <h4>Fecha: {{ moment(selectedDate).format('DD/MM/YYYY') }}</h4>           
    </b-col>
    <b-col md="6" >
      <SearchGroup/>            
    </b-col>
    <b-col md="6" >
      <div class="float-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"
          @click="handlerCreateGroup"
          v-if="idUser"
        >
          Crear grupo
        </b-button>            
      </div>
    </b-col>
  </b-row>
  <b-row> 
    <b-col v-if="isLoadingGroups">
      <div class="text-center mt-2 mb-2" >
        <div>
          <strong>Cargando grupos</strong>              
        </div>
        <b-spinner label="Loading..." variant="info" />
      </div>
    </b-col>  
    <b-col md="12" v-if="!groupsFiltered.length && !isLoadingGroups">
      <b-alert show variant="danger"  class="mt-2">
        <div class="alert-body text-center"> 😣 <span>No se han encontrado grupos</span></div>
      </b-alert>               
    </b-col>    
    <b-col md="12" v-for="group in groupsFiltered" :key="group.uuid">           
      <Group :group="group"/>                           
    </b-col>    
  </b-row>
</div>  
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
import Ripple from 'vue-ripple-directive'

import SearchGroup from "@/modules/calendar/components/groups/SearchGroup"
import Group from "@/modules/calendar/components/groups/Group"
import EditGroup from "@/modules/calendar/components/groups/EditGroup"

export default {
  directives: {
    Ripple,
  },
  components: {
    SearchGroup,
    Group,
    EditGroup
  },
  async created() {
    await this.fetchCoordinators()
    await this.fetchLocations()
    const payload = {
      start: this.selectedDate.format("YYYY-MM-DD"),
      end: this.selectedDate.format("YYYY-MM-DD")
      // end: this.selectedDate.endOf('month').format("YYYY-MM-DD")            
    }    
    await this.fetchGroups( payload )
  },
  computed:{
    ...mapState('auth',['idUser']),
    ...mapState('calendar', ['selectedDate','isLoadingGroups']),  
    ...mapGetters('calendar', ['groupsFiltered'])                
  },
  methods:{
    moment,
    ...mapActions('calendar',['fetchGroups','fetchCoordinators','fetchLocations']),   
    handlerCreateGroup(){
			this.$root.$emit('bv::show::modal', 'modal-create-group')                                 
    } 
  }
}
</script>