
export default {    
    agencies:[],
    agents:[],
    backupLocations:[],
    coordinators:[],
    eventsData: [],
    eventsForYear:[],
    eventsSelectedDate:[],
    filters:{
        idHotels:[ 0 ],
        idLocations:[ 0 ],
        idTypeGroups:[ 0 ],
        query: '',
    },
    groups:[],
    groupTypes: [],
    hotels:[],
    isLoadingCalendar: true,
    isLoadingGroups: false,
    locations: [],
    modeCalendar: 'month',
    montages:  [],    
    selectedDate: null,    
    selectedEvent: null,    
    selectedGroup: null,  
}