import Vue from 'vue'
import { formatEvents, makeArrayEvents } from '../helpers/eventsHelper'
import { formatGroups } from '../helpers/groupsHelper'
import { formatLocations } from '../helpers/locationsHelper'

export async function fetchHotels(context){ 
       
    try {         
        const response = await Vue.axios({               
            url:`/catalogs/hotels/`  
        })        
        const payload = response && response.data  
        payload.unshift( { name: 'Todos', id: 0 } )                                                   

        context.commit('setHotels', payload )                         
                
    } catch (error) {        
        console.log('Ha ocurrido un eror', error)        
    } finally {
        console.log('La petición para obtener la lista de hoteles se ha terminado')
    }
}

export async function fetchEvents(context, params){ 
    const { start, end, currentDayMonth } = params    
    
    try {
        context.commit('setLoadingCalendar', true )  
        context.commit('unsetEventsMonth', [] )  
        const response = await Vue.axios({               
            url:`/grupos/calendario/?fini=${start}&ffin=${end}`  
        })
        
        const payload = response && response.data 
        
        const events = formatEvents( payload )         
        if( events.length ){
            const { eventsData } = makeArrayEvents( events )                      
            context.commit('setEventsMonth', eventsData ) 
            //si hay eventos en el dia obtengo esos eventos o sino mando un array vacio
            const eventsDay = eventsData[currentDayMonth] ? [...eventsData[currentDayMonth] ] : []
            context.commit('setEventsSelectedDate', eventsDay)              
            context.commit('setEventsMonthCountForYear', {})                                                    
        }                

    } catch (error) {   
        console.log(error)     
        context.commit('setCalendarError', error.message)
    } finally {
        context.commit('setLoadingCalendar', false )  
        console.log('La petición para obtener la lista de eventos se ha terminado')
    }
}

export async function fetchGroupTypes(context){ 
       
    try {         
        const response = await Vue.axios({               
            url:`/catalogs/tipogrupo/`  
        })        
        const payload = response && response.data
        payload.unshift({ tipogruponame: 'Todos', id: 0 })
        context.commit('setGroupTypes', payload )                         
                
    } catch (error) {        
        console.log('Ha ocurrido un eror', error)        
    } finally {
        console.log('La petición para obtener los tipos de grupos se ha terminado')
    }
}

export async function fetchGroups(context, params){ 
    const { start, end } = params    
    try {
        context.commit('unsetGroupsMonth', [] )  
        const response = await Vue.axios({               
            url:`/grupos/grupo/?fini=${start}&ffin=${end}`  
        })
        
        const payload = response && response.data  
        const groups = formatGroups( payload )                 
                    
        if( groups.length ){                       
            context.commit('setGroupsMonth', groups )                         
        }                

    } catch (error) {        
        console.log('Ha ocurrido un eror', error)        
    } finally {
        console.log('La petición para obtener la lista de grupos se ha terminado')
    }
}

export async function fetchGroupsByName(context, nameGroup){                     
    try {       
        context.commit('setLoadingGroups', true )  
        const response = await Vue.axios({ 
            url:`/grupos/grupo/`,
            params: {
                name: nameGroup                
            }  
        })
        const payload = response && response.data 
        const groups = formatGroups( payload )   
        if( groups.length ){                       
            context.commit('setFilterGroups', '' )                         
            context.commit('addToGroups', groups)                         
        }                                   
    } catch (error) {        
        console.log('Ha ocurrido un eror', error)  
        context.commit('setCalendarError', error.message)

    } finally {        
        context.commit('setLoadingGroups', false )  
        console.log('La petición para obtener grupos por nombre se ha terminado')
    }
}

export async function fetchCoordinators(context){ 
       
    try {         
        const response = await Vue.axios({               
            url:`/catalogs/coordinadores/`  
        })        
        const payload = response && response.data                                        
        context.commit('setCoordinators', payload )                         
                
    } catch (error) {        
        console.log('Ha ocurrido un eror', error)        
    } finally {
        console.log('La petición para obtener la lista de coordinadores se ha terminado')
    }
}

export async function fetchLocations(context){        
    try {         
        const response = await Vue.axios({               
            url:`/catalogs/locaciones/`  
        })        
        const payload = response && response.data  
               
        const { locations } = formatLocations( payload )         
                                                 
        context.commit('setLocations', locations )                                         
    } catch (error) {        
        console.log('Ha ocurrido un eror', error)        
    } finally {
        console.log('La petición para obtener la lista de locaciones se ha terminado')
    }
}

export async function saveGroup(context, group ){        
    try {         
        const response = await Vue.axios({
            method:'POST',                        
            url:`/grupos/grupo/`,
            data: group
        })               
        const respuesta = response && response.data 
        const { status } = respuesta            
        if( status ){
            return { ok: true, message: 'Se ha guardado el grupo'}
        } 
        return { ok: false, message: 'Error, no se guardó el grupo' }
        
    } catch (error) {        
        console.log('Ha ocurrido un eror', error)        
    } finally {
        console.log('La petición para obtener guardar el grupo se ha terminado')
    }
}

export async function updateGroup(context, group) { 
    const { id } = group       

    try {         
        const response = await Vue.axios({
            method:'PUT',                        
            url:`/grupos/grupo/${id}/`,
            data: group
        })  
        const respuesta = response && response.data        
        const { status } = respuesta
        if( status ){
            // console.log( group )            
            // context.commit('updateDataGroup', group )                         
            return { ok: true, message: 'Se ha actualizado el grupo 🎉🎉'}
        } 
        return { ok: false, message: 'Error, no se pudo actualizar el grupo 🥺😔' }
            
    } catch (error) {
        console.log(error)
    } finally {        
        console.log('La petición para actualizar el grupo finalizó')
    }
}

export async function validateEvent(context, evento) {     
    try { 
        const response = await Vue.axios({
            method:'POST',                        
            url:`/grupos/validacion/`,
            data: evento
        })          
        const respuesta = response && response.data         
        const { status } = respuesta
                
        if( status ){             
            return { ok: true, message: 'Es posible el guardado del evento'}
        } else {
            const { nombreevento, nombregrupo } = respuesta             
            const message = `No se puede hacer el guardado, locación ocupada por el grupo ${ nombregrupo } con el evento ${ nombreevento } 🥺😔` 
            return { ok: false, message }
        }
    } catch (error) {
        console.log('Ha ocurrido un eror', error)                
    } finally {  
        console.log('La petición para disponibilidad de locación para el evento finalizó')
    }
}

export async function saveEvent(context, event) {      
    try {                  
        const response = await Vue.axios({
            method:'POST',                        
            url:`/grupos/calendario/`,
            data: event
        })  
        const respuesta = response && response.data 
        const { status } = respuesta

        if( status ){             
            return { ok: true, message: '!Se ha guardo la información del evento 🎉🎉!'}
        } else {                                   
            return { ok: false, message: `Ocurrió un error con el guardado 🥺😔`  }
        }

    } catch (error) {
        console.log('Ha ocurrido un eror', error)                        
    } finally {  
        console.log('La petición para guardar el evento finalizó')
    }
}

export async function updateEvent(context, evento) {   
    const { id } = evento        
    try {         
        const response = await Vue.axios({
            method:'PUT',                        
            url:`/grupos/calendario/${id}/`,
            data: evento
        })  
        const respuesta = response && response.data         
        const { status } = respuesta

        if( status ){ 
            context.commit('updateEvent', evento )                         
            return { ok: true, message: '!Se ha actualizado la información del evento 🎉🎉!'}
        } else {                                   
            return { ok: false, message: `Ocurrió un error con la actualización del evento 🥺😔`  }
        }

    } catch (error) {
        console.log('Ha ocurrido un eror', error)        
    } finally {        
        console.log('La petición para actualizar el evento finalizó (calendar)')
    }
}

export async function deleteEvent(context, evento) {
    const { id } = evento  
        
    try {   
        context.commit('setLoadingCalendar', true )  
        const response = await Vue.axios({
            method:'DELETE',                        
            url:`/grupos/calendario/${id}/`,                        
        })  
        const respuesta = response && response.data 
        const { status } = respuesta
        
        if( status ){                                                        
            return { ok: true, message: '!Se ha borrado el evento 🎉🎉!'}
        } else {                                   
            return { ok: false, message: `Ocurrió un error con el borrado del evento 🥺😔`  }
        }

    } catch (error) {
        commit('setCalendarError', error.message)
    } finally {  
        context.commit('setLoadingCalendar', false )  
        console.log('La petición para borrar el evento finalizó (calendar)')
    }
}