
export default [
    {
        path: '/',
        name: 'calendar',
        component: () => import(/*webpackChunkName: "CalendarView"*/'@/modules/calendar/views/Calendar'),
        meta: {
            layout: 'full',            
        },
    },
    {
        path: '*',
        component: () => import(/*webpackChunkName: "CalendarView"*/'@/modules/calendar/views/Calendar'),
        meta: {
            layout: 'full',            
        },     
    }    
]