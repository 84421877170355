import { formatGroups } from '../helpers/groupsHelper'
import { createEventUpdated } from '../helpers/eventsHelper'

export function setLoadingCalendar(state, bool) {
    state.isLoadingCalendar = bool
}

export function setLoadingGroups(state, bool) {
    state.isLoadingGroups = bool
}

export function setEventsMonth(state, events) {    
    state.eventsData = events
}

export function removeEventInMonth(state, data) {        
    const { day, uuid } = data
    let eventsMonth = [...state.eventsData]        
    eventsMonth[day] = eventsMonth[day].filter( event => event.uuid !== uuid )
    state.eventsData = eventsMonth
    state.eventsSelectedDate = state.eventsSelectedDate.filter( event => event.uuid !== uuid )         
}

export function setGroupsMonth(state, groups) {
    state.groups = groups
}

export function addToGroups(state, groups) {    
    //mescla los grupos anteriores con el nuevo y elimina los duplicados por si acaso
    state.groups = [...new Set( [...groups, ...state.groups] ) ]
}

export function setCoordinators(state, coordinators) {
    state.coordinators = coordinators
}

export function setEventsSelectedDate(state, events) {
    state.eventsSelectedDate = events 
}
export function setLocations(state, locations) {
    state.locations = locations
}
export function setIdHotelsFilter(state, idHotels) {
    state.filters.idHotels = idHotels
}
export function setIdLocationsFilter(state, idLocations) {
    state.filters.idLocations = idLocations
}
export function setIdTypeGroupsFilter(state, idTypeGroups) {
    state.filters.idTypeGroups = idTypeGroups
}


export function setMontages(state, montages) {    
    state.montages = [...new Set( [...montages, ...state.montages] ) ]
}

export function setHotels(state, hotels) {
    state.hotels = hotels
}

export function setGroupTypes(state, groupTypes) {
    state.groupTypes = groupTypes
}

export function setFilterGroups(state, string) {
    state.filters.query = string.toLowerCase()
}

export function setEventsMonthCountForYear(state ) {
    const counters = [
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
        Math.floor((Math.random() * 100) + 1),
    ]
    state.eventsForYear = counters
}

export function setModeCalendar(state, mode) {
    state.modeCalendar = mode
}

export function setSelectedDate(state, date) {    
    state.selectedDate = date
}
export function setMonthYearNumber(state, date) {    
    state.selectedDate = date
}

export function setSelectedEvent(state, event) {
    state.selectedEvent = event
}

export function setSelectedGroup(state, group) {
    state.selectedGroup = group
}

export function setCalendarError(state, error) {
    state.calendarError = error
}

export function unsetEventsMonth(state, data) {
    state.eventsData = data
}

export function unsetGroupsMonth(state, data) {
    state.eventsData = data
}

export function updateEvent(state, event){
    const { uuid, tab } = event
    const dataEvent = createEventUpdated( event )
   
    if( tab === 'events'){ 
        //actualizo lo que hay en eventos del dia seleccionado             
        let listEventsDay = [...state.eventsSelectedDate ]   
        const idx = listEventsDay.map( event => event.uuid ).indexOf(uuid)                 
        listEventsDay[idx] = dataEvent
        state.eventsSelectedDate = listEventsDay          
    }
}
export function updateDataGroup(state, group) {
    const { uuid, events } = group
    group.calendario = events    
    let listGroups = [...state.groups]        
    const [ grupo ] = formatGroups([group])    
    const idx = listGroups.map( group => group.uuid ).indexOf(uuid)    
    listGroups[idx] = grupo
    state.groups = listGroups    
}
