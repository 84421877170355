import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'

import router from './router'
import store from './store'
import App from './App.vue'
import Modal from 'ant-design-vue/lib/modal' //lo llamo aqui porque daba problemas en vue-ant-desing.js

// Axios Mock Adapter
import '@/@fake-db/db'

// 3rd party plugins
require('./libs')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Modal)
Vue.use(vueCustomElement)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

App.store = store
App.router = router
Vue.customElement('calendar-app', App)
